<template>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h3> Modifier role {{permission}}</h3>
                <h3> old role {{role.permissions}}</h3>
                
                
                <router-link to="/getrole">
                  <span class="float-right btn btn-info">retour</span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="addroles()">
  
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label for="first" style="font-size: 14px">Nom </label>
                        <input
                          type="text"
                          v-model="role.nom"
                          class="form-control"
                          placeholder=""
                          id="first"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-12">
                   <h5>Donner les permissions</h5> 
                    </div>
                    <div class="col-6">
                     Gestion des objectifs
                    </div>
                      <div class="col-6">
                          <input type="checkbox" :checked="true" aria-label="Checkbox for following text input" :value="objectif" />
                      </div>
                      <div class="col-6">
                     Gestion des articles
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="articles" v-model="permission"/>
                      </div>
                      <div class="col-6">
                     Gestion des clients
                     
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="clients" v-model="permission"/>
                      </div>
                      <div class="col-6">
                     Gestion des roles
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="roles" v-model="permission"/>
                      </div>
                      <div class="col-6">
                     Gestion des utilisateurs
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="utilisateurs" v-model="permission"/>
                      </div>
                      <div class="col-6">
                     Gestion des groupes 
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="groupes"  v-model="permission"/>
                      </div>
                      <div class="col-6">
                     Gestion des commandes
                    </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="commandes" v-model="permission"/>
                      </div>
                      <div class="col-6">
                          Gestion des zones
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="zones"  v-model="permission"/>
                      </div>
  
                      <div class="col-6">
                          Gestion des emplacements
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="emplacements"  v-model="permission"/>
                      </div>
  
                      <div class="col-6">
                          Gestion des stocks
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="articles_stocks"  v-model="permission"/>
                      </div>
  
                      <div class="col-6">
                          Gestion des demandes_stocks
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="demande_stocks"  v-model="permission"/>
                      </div>
                      <div class="col-6">
                              Gestion des visites
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="visites" v-model="permission"/>
                      </div>
  
                      <div class="col-6">
                          Gestion des formations
                      </div>
                      <div class="col-6">
                          <input type="checkbox" aria-label="Checkbox for following text input" :value="formations" v-model="permission"/>
                      </div>
                   
                     
                  </div>
                  <br>
                  <button class="btn btn-success">Ajouter le role</button>
  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from "@/axios";
  
  export default {
    data() {
      return {
        test:[
    
        {
        "id": 1,
        "name": "Business",
        "checked": true
    },
    {
        "id": 2,
        "name": "Business 2",
        "checked": false
    },
      
    ],
       nom:"",
       permission:[],
       role:{},
       objectif:
       {
      titre: "gestion des objectifs",
      code:"1"
       },
       articles:
      {
      titre: "gestion des articles",
      code:"2"
       },
  
       clients:
      {
      titre: "gestion des clients",
      code:"3"
       },
  
       
       roles:
      {
      titre: "gestion des roles",
      code:"4"
       },
  
      utilisateurs:
      {
      titre: "gestion des utilisateurs",
      code:"5"
       },
  
       groupes:
      {
      titre: "gestion des groupes",
      code:"6"
       },
       commandes:
      {
      titre: "gestion des commandes",
      code:"7"
       },
       zones:
      {
      titre: "gestion des zones",
      code:"8"
       },
       visites:
      {
      titre: "gestion des visites",
      code:"9"
       },
  
  
       emplacements:
      {
      titre: "gestion des emplacements",
      code:"10"
       },
  
       articles_stocks:
      {
      titre: "gestion des articles/stocks",
      code:"11"
       },
  
       demande_stocks:
      {
      titre: "gestion des articles/stocks",
      code:"12"
       },
  
       visites:
      {
      titre: "gestion des visites",
      code:"13"
       },
      formations:
      {
      titre: "gestion des formations",
      code:"14"
      },
  
  
      };
    },
  
    created() {
        this.onerole();
    },
    methods: {

  onerole() {


HTTP
  .get("roles/getrolesbyid/" + this.$route.params.id)
  .then((response) => {
    console.log(response.data);
    this.role = response.data;

  })
  .then((err) => console.log(err));
},




  
      addroles() {
        //ajouter quantités
  
       
  
        HTTP.post("roles/addrole", 
        
        {
            nom: this.nom,
            permissions:this.permission,
         }
        
        
        
      ).then((response) => {
            Toast.fire({
              icon: "success",
              title: "role ajouté",
            });
  
  
  
          });
  
          
      },
    },
  };
  </script>
  <style scoped></style>
  